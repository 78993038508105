<!--
 * @Author: xiaohe
 * @LastEditors: xiaohe
-->
<template>
  <div class="uolad">
    <a-upload
      :disabled="isDisable"
      :accept="accept"
      :action="VUE_APP_FIRST_URL + uloadUrl"
      :data="{ fileUrl: statuStr }"
      :list-type="listType"
      :file-list="uloadList"
      @preview="handlePreview"
      :remove="onRemove"
      @change="handleChange"
      :beforeUpload="beforeUpload"
    >
      <template v-if="!isDisable">
        <a-icon v-if="uloadList.length < liNum" type="plus" />
        <div  v-if="uloadList.length < liNum" class="ant-upload-text">{{text}}</div>
      </template>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: []
    },
    uloadUrl: {
      type: String,
      default: "/api/contract/upload"
    },
    statuStr: {
      type: String,
      default: "entCertForm"
    },
    listType: {
      type: String,
      default: "picture-card"
    },
    isDisable: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: ".png,.jpg,.jpeg"
    },
    isMbNum: {
      type: [String, Number],
      default: 5
    },
    text: {
      type: [String, Number],
      default: ""
    },
    liNum: {
      type: [String, Number],
      default: 6
    }
  },
  components: {},
  computed: {
    // 图片地址
    uloadList: {
      get() {
        return this.list;
      },
      set(val) {
        this.$emit("update:list", val);
      }
    }
  },
  data() {
    return {
      VUE_APP_FIRST_URL: "",
      previewVisible: false,
      previewImage: "",
      isType: ""
    };
  },
  mounted() {
    this.VUE_APP_FIRST_URL = process.env.VUE_APP_FIRST_URL;
  },
  methods: {
    handleChange({ fileList }) {
      let arr = [];
      arr.push(...fileList);
      this.uloadList = arr.filter((item, index) => arr.indexOf(item) === index);
    },
    // 预览
    handlePreview(e) {
      this.previewImage = e.response ? e.response.data.uri : e.url;
      this.previewVisible = true;
      // }
    },
    // 取消预览
    handleCancel() {
      this.previewVisible = false;
    },
    onRemove(file) {
      if (!this.uloadList.length) {
        setTimeout(() => {
          this.uloadList = [];
        }, 800);
      }
      let index = this.uloadList.findIndex(item => {
        return item.uid === file.uid;
      });
      this.uloadList.splice(index, 1);
    },
    beforeUpload(file, fileList) {
      const isLt5M = file.size / 1024 / 1024 < this.isMbNum;
      if (!isLt5M) {
        this.$message.error(
          `上传文件大小不能超过${this.isMbNum} MB,请将图片删除!`
        );
      this.onRemove();
      }
      return isLt5M;
    }
  }
};
</script>

<style lang="less" scoped></style>
